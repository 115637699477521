body {
	margin: 0;
	font-family: "Roboto", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1 {
	font-size: 24px;
}

.center-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

div,input,select,textarea,button {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
